/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// This file is generated by scripts/gen-public.js
// Do not modify it manually
// Run 'yarn gen-public' to regenerate it if you add new files to the public directory

import assets_animations_fireworkAnimationJSON from "/app/src/assets/animations/firework_animation.json";
import assets_icons_arrowIconSVG from "/app/src/assets/icons/arrowIcon.svg";
import assets_icons_correctAnswerWEBP from "/app/src/assets/icons/correct-answer.webp";
import assets_icons_wrongAnswerWEBP from "/app/src/assets/icons/wrong-answer.webp";
import assets_images_banners_prizeBoxesWEBP from "/app/src/assets/images/banners/prizeBoxes.webp";
import assets_images_banners_prizeImageWEBP from "/app/src/assets/images/banners/prizeImage.webp";
import assets_images_createEvent_background1WEBP from "/app/src/assets/images/createEvent/background-1.webp";
import assets_images_createEvent_background10WEBP from "/app/src/assets/images/createEvent/background-10.webp";
import assets_images_createEvent_background11WEBP from "/app/src/assets/images/createEvent/background-11.webp";
import assets_images_createEvent_background12WEBP from "/app/src/assets/images/createEvent/background-12.webp";
import assets_images_createEvent_background13WEBP from "/app/src/assets/images/createEvent/background-13.webp";
import assets_images_createEvent_background14WEBP from "/app/src/assets/images/createEvent/background-14.webp";
import assets_images_createEvent_background15WEBP from "/app/src/assets/images/createEvent/background-15.webp";
import assets_images_createEvent_background16WEBP from "/app/src/assets/images/createEvent/background-16.webp";
import assets_images_createEvent_background17WEBP from "/app/src/assets/images/createEvent/background-17.webp";
import assets_images_createEvent_background18WEBP from "/app/src/assets/images/createEvent/background-18.webp";
import assets_images_createEvent_background19WEBP from "/app/src/assets/images/createEvent/background-19.webp";
import assets_images_createEvent_background2WEBP from "/app/src/assets/images/createEvent/background-2.webp";
import assets_images_createEvent_background20WEBP from "/app/src/assets/images/createEvent/background-20.webp";
import assets_images_createEvent_background21WEBP from "/app/src/assets/images/createEvent/background-21.webp";
import assets_images_createEvent_background22WEBP from "/app/src/assets/images/createEvent/background-22.webp";
import assets_images_createEvent_background23WEBP from "/app/src/assets/images/createEvent/background-23.webp";
import assets_images_createEvent_background24WEBP from "/app/src/assets/images/createEvent/background-24.webp";
import assets_images_createEvent_background25WEBP from "/app/src/assets/images/createEvent/background-25.webp";
import assets_images_createEvent_background26WEBP from "/app/src/assets/images/createEvent/background-26.webp";
import assets_images_createEvent_background27WEBP from "/app/src/assets/images/createEvent/background-27.webp";
import assets_images_createEvent_background3WEBP from "/app/src/assets/images/createEvent/background-3.webp";
import assets_images_createEvent_background4WEBP from "/app/src/assets/images/createEvent/background-4.webp";
import assets_images_createEvent_background5WEBP from "/app/src/assets/images/createEvent/background-5.webp";
import assets_images_createEvent_background6WEBP from "/app/src/assets/images/createEvent/background-6.webp";
import assets_images_createEvent_background7WEBP from "/app/src/assets/images/createEvent/background-7.webp";
import assets_images_createEvent_background8WEBP from "/app/src/assets/images/createEvent/background-8.webp";
import assets_images_createEvent_background9WEBP from "/app/src/assets/images/createEvent/background-9.webp";
import assets_images_createEvent_createEventFirstScreenWEBP from "/app/src/assets/images/createEvent/createEventFirstScreen.webp";
import assets_images_createEvent_smiles_smileCoolWEBP from "/app/src/assets/images/createEvent/smiles/smileCool.webp";
import assets_images_createEvent_smiles_smileHmWEBP from "/app/src/assets/images/createEvent/smiles/smileHm.webp";
import assets_images_createEvent_smiles_smileStarsWEBP from "/app/src/assets/images/createEvent/smiles/smileStars.webp";
import assets_images_error_ErrorPageIconWEBP from "/app/src/assets/images/error/Error page icon.webp";
import assets_images_eventsCard_archiveEventSVG from "/app/src/assets/images/eventsCard/archive-event.svg";
import assets_images_eventsCard_archiveIconSVG from "/app/src/assets/images/eventsCard/archive-icon.svg";
import assets_images_eventsCard_draftIconSVG from "/app/src/assets/images/eventsCard/draft-icon.svg";
import assets_images_eventsCard_duplicateEventSVG from "/app/src/assets/images/eventsCard/duplicate-event.svg";
import assets_images_eventsCard_editEventSVG from "/app/src/assets/images/eventsCard/edit-event.svg";
import assets_images_eventsCard_eventAnalyticsSVG from "/app/src/assets/images/eventsCard/event-analytics.svg";
import assets_images_eventsCard_hideFromWebsiteSVG from "/app/src/assets/images/eventsCard/hide-from-website.svg";
import assets_images_eventsCard_previewCardEventWEBP from "/app/src/assets/images/eventsCard/preview-card-event.webp";
import assets_images_help_percentsAndBagsWEBP from "/app/src/assets/images/help/percentsAndBags.webp";
import assets_images_index_GameControllerWEBP from "/app/src/assets/images/index/Game-controller.webp";
import assets_images_index_RocketWEBP from "/app/src/assets/images/index/Rocket.webp";
import assets_images_index_SaleStickerWEBP from "/app/src/assets/images/index/Sale-sticker.webp";

const publicFiles = {
  assets: {
    animations: {
      fireworkAnimationJSON: assets_animations_fireworkAnimationJSON,
    },
    icons: {
      arrowIconSVG: assets_icons_arrowIconSVG,
      correctAnswerWEBP: assets_icons_correctAnswerWEBP,
      wrongAnswerWEBP: assets_icons_wrongAnswerWEBP,
    },
    images: {
      banners: {
        prizeBoxesWEBP: assets_images_banners_prizeBoxesWEBP,
        prizeImageWEBP: assets_images_banners_prizeImageWEBP,
      },
      createEvent: {
        background1WEBP: assets_images_createEvent_background1WEBP,
        background10WEBP: assets_images_createEvent_background10WEBP,
        background11WEBP: assets_images_createEvent_background11WEBP,
        background12WEBP: assets_images_createEvent_background12WEBP,
        background13WEBP: assets_images_createEvent_background13WEBP,
        background14WEBP: assets_images_createEvent_background14WEBP,
        background15WEBP: assets_images_createEvent_background15WEBP,
        background16WEBP: assets_images_createEvent_background16WEBP,
        background17WEBP: assets_images_createEvent_background17WEBP,
        background18WEBP: assets_images_createEvent_background18WEBP,
        background19WEBP: assets_images_createEvent_background19WEBP,
        background2WEBP: assets_images_createEvent_background2WEBP,
        background20WEBP: assets_images_createEvent_background20WEBP,
        background21WEBP: assets_images_createEvent_background21WEBP,
        background22WEBP: assets_images_createEvent_background22WEBP,
        background23WEBP: assets_images_createEvent_background23WEBP,
        background24WEBP: assets_images_createEvent_background24WEBP,
        background25WEBP: assets_images_createEvent_background25WEBP,
        background26WEBP: assets_images_createEvent_background26WEBP,
        background27WEBP: assets_images_createEvent_background27WEBP,
        background3WEBP: assets_images_createEvent_background3WEBP,
        background4WEBP: assets_images_createEvent_background4WEBP,
        background5WEBP: assets_images_createEvent_background5WEBP,
        background6WEBP: assets_images_createEvent_background6WEBP,
        background7WEBP: assets_images_createEvent_background7WEBP,
        background8WEBP: assets_images_createEvent_background8WEBP,
        background9WEBP: assets_images_createEvent_background9WEBP,
        createEventFirstScreenWEBP:
          assets_images_createEvent_createEventFirstScreenWEBP,
        smiles: {
          smileCoolWEBP: assets_images_createEvent_smiles_smileCoolWEBP,
          smileHmWEBP: assets_images_createEvent_smiles_smileHmWEBP,
          smileStarsWEBP: assets_images_createEvent_smiles_smileStarsWEBP,
        },
      },
      error: {
        ErrorPageIconWEBP: assets_images_error_ErrorPageIconWEBP,
      },
      eventsCard: {
        archiveEventSVG: assets_images_eventsCard_archiveEventSVG,
        archiveIconSVG: assets_images_eventsCard_archiveIconSVG,
        draftIconSVG: assets_images_eventsCard_draftIconSVG,
        duplicateEventSVG: assets_images_eventsCard_duplicateEventSVG,
        editEventSVG: assets_images_eventsCard_editEventSVG,
        eventAnalyticsSVG: assets_images_eventsCard_eventAnalyticsSVG,
        hideFromWebsiteSVG: assets_images_eventsCard_hideFromWebsiteSVG,
        previewCardEventWEBP: assets_images_eventsCard_previewCardEventWEBP,
      },
      help: {
        percentsAndBagsWEBP: assets_images_help_percentsAndBagsWEBP,
      },
      index: {
        GameControllerWEBP: assets_images_index_GameControllerWEBP,
        RocketWEBP: assets_images_index_RocketWEBP,
        SaleStickerWEBP: assets_images_index_SaleStickerWEBP,
      },
    },
  },
};

export default publicFiles;
